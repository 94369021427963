

.products__container{
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(17rem , 1fr));
    gap:2rem;
}


.products__list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:4rem;
}

.selectItem{
    width: 17.2rem;
}


@media only screen and (max-width: 600px) {
    .products{
        margin-top: 3rem;
    }
    .products__container{
        grid-template-columns: repeat(auto-fill , minmax(15rem , 1fr));
        gap:1rem;
    }

}