.mobilFilter{
    display: none;
}


@media only screen and (max-width: 1024px) {
   
    .mobilFilter{
        display: block;
        background: var(--bg-grey);
        padding:2rem 0rem;
    }
    .mobilFilter button {
        font-family: 'Roboto', sans-serif !important;
        font-size: 1.4rem !important;
    }
  
    .commonFilter{
        display: grid ;
        grid-template-columns: repeat(2, 1fr);
        gap:1rem;
    }
    .commonFilter .btn__filter__search{
        height: 38px ;
        border-radius: 2px!important;
    }

  
}