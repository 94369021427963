.products__container{
    margin-top: 3.5rem;
}

.brand__img img{
    height: 20rem;
}

.brand__container{
    text-align: center;
    margin-top: 3rem !important;
}

.brand__container h3{
    cursor: pointer;
}

.shop__category{
    margin-top: 2.5rem ;
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(13rem , 1fr));
    gap: 1.8rem;
}


@media only screen and (max-width: 600px) {
    .shop__category{
        grid-template-columns: repeat(auto-fill , minmax(13rem , 1fr));
        gap: 1.2rem;
    }
}