.partner__container{
    margin-top:8rem !important;
}
.partner__container h3{
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
}

   .partner{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(11rem , 1fr));
    gap:1.5rem;
    place-items: center;
}
    .partnerImg{
        padding: 1rem 0rem;
}
.partnerImg img{
    width: 15rem;
    height: 5rem;
    object-fit: contain;
}

@media only screen and (max-width: 1024px) {
    .partner__container{
        margin-top: 2rem !important;
    }
      .partner__container h3{
        margin-bottom: 1rem;
    }
}





