.service__container {
    padding: 3.8rem 0rem;
    display: grid;
    grid-template-columns: 30rem 1fr;
    gap: 2rem;
}

.container__bg {
    background: #F3F3F3;
}

.service__left {
    background: #fff;
    padding: 3rem 2rem;
}

.service__right {
    background: #fff;
    padding: 3rem 2rem;
}

.service__cards {
    margin-top: 1.9rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
    column-gap: 2rem;
    row-gap: 3rem;
}

.service__card__body h3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.service__right__title {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.service__right__title a {
    font-weight: 500;
    font-size: 1.8rem;
    color: #D4252A;
}

.service__select {
    margin-top: 2rem;
}

.service__select .css-13cymwt-control {
    height: 45px !important;
}

.service__select2 .css-13cymwt-control {
    height: 45px !important;
}

.service__category {
    margin-top: 2.5rem;
}

.service__category h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 1.8rem;
}

.service__category__filter {
    margin-bottom: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
}

.service__category__filter label {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #181818;
    cursor: pointer;
}

.service__category__filter label input {
    width: 1.8rem;
    height: 1.8rem;
}

.serviceFilter {
    width: 100%;
    height: 4.5rem;
    border-radius: 5px;
    border: 1px solid #B7B7B7;
    padding-left: 1rem;
    margin-bottom: 1.5rem;
}

.serviceFilter::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #C0C0C0;
}

.service__info {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid #D2D2D2;
}

.service__info p {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #181818;
}

.service__info p svg {
    font-size: 1.8rem;
    color: var(--color-primary);
    width: 2rem;
}

.service__info p a {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #181818;
}

.service__img {
    height: 23rem;
    border-radius: 3px;
    margin-bottom: 2.3rem;
}

.service__note {
    margin-top: 2.2rem;
}

.service__note h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0.8rem;
}

.service__note p {
    margin-bottom: 0.8rem;
}

.service__slider {
    display: grid;
    grid-template-columns:  1fr;
    gap: 3.5rem;
}

.slideShow {
    width: 46rem;
}

.slideShow img {
    height: 30rem;
    border-radius: 5px;
}


.service__works h3 {
    padding: 2rem 0rem;
    border-bottom: 1px solid #D2D2D2;
}

.service__works p {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0rem;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #181818;
    border-bottom: 1px solid #D2D2D2;
    flex-wrap: wrap;
    gap: 1rem;
}

.service__works p span {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #858585;
}

.service__works__mobil {
    display: none;
}

@media only screen and (max-width: 1280px) {
    .slideShow {
        width: 38rem;
    }

}

@media only screen and (max-width: 1024px) {
    .service__container {
        grid-template-columns: 1fr;
    }

    .service__item__select {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .service__category__filter {
        grid-template-columns: 1fr 1fr;
    }

    .mobilW100 {
        width: 100%;
    }

    .service__left .service__left__title {
        display: none;
    }

    .service__container {
        padding: 0rem 0rem;
    }

    .serviceFilterDemo {
        display: none;
    }
}

@media only screen and (max-width: 912px) {
    .service__works__mobil {
        display: block;
    }

    .service__slider {
        display: none;
    }

}



@media only screen and (max-width: 600px) {
    .service__cards {
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
        column-gap: 1rem;
        row-gap: 2rem;
    }
    .service__category__filter label {
        font-size: 12px;
    }
    .service__right {
        background: #fff;
        padding: 0rem 1rem;
    }
    .service__container{
        gap:0rem;
    }
    .service__left {
        padding: 2rem 1rem;
    }
    .service__category__filter label input {
        width: 1.5rem;
        height: 1.5rem;
    }
    .service__info .mobilNone{
        display: none !important;
    }
    .service__info{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .mobilLocation-100{
        grid-column: 1 / -1;
    }
}