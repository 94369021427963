@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap');

.radio_part_form{
    display: flex;
    gap: 2rem;
    margin-right: 5rem;
}

.radio_items{
    font-size: 1.6rem;
    color: #181818;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.activeRadio{
   accent-color:green!important;
}

.colorWhite{
    color:#fff !important;
}