.map-wrap {
  margin-top: 2rem;
    width: 100%;
  }
  
  .map {
    width: 100%;
    height:260px;
    border-radius: 3px;
  }

  .maplibregl-ctrl-bottom-right{
         display: none;
  }