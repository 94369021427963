.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  display: none;
  transition: all 0.3s ease-in-out;
}

.modal.is-open {
  display: flex;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease-in-out;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  position: relative;
  display: none;
}

.modal-content.showModal{
    display: block;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer !important;
  color:#545454;
  font-size: 2rem;
  font-weight: 600;
}

.modal-content{
    width: 580px;
    height: 80vh;
    overflow-y: auto;
}

.modal-content::-webkit-scrollbar {
  width: 3px;
}

.modal-content::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #D4252A;
  border-radius: 100px;
}

.modalItems{
    padding: 4rem 6rem;
    display: flex;
    justify-content: center;
    gap:5rem;
}

.payItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.payModal .payItem p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color:var(--color-black);
}
.modalBtn{
  display: flex;
  justify-content: center;
}
.modalBtn button{
  padding: 1rem 5.5rem;
}

/* .payItem path:hover{
  fill:#D4252A;
} */



.overlay-search{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  background: rgba(14, 14, 14, 0.6);
}

.overlay-search-mobil{
  display: none;
}

.overlay-search.active{
  opacity: 1;
  visibility: visible;
}


.shop__info__modal{
  padding:3rem 5rem;
}

.shop__info__item{
  margin-bottom:1rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 1rem 0rem;
  border-bottom: 1px solid #EBEBEB;
  padding-left: 30%;
}

.shop__info__item button{
  width: 3.9rem;
  height: 3.9rem;
  background: #EBEBEB;
  border-radius: 100%;
  padding:5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:var(--color-primary);
  font-size: 2rem;
}

.shop__info__item .right__info{
  flex-basis: 80%;
}

.right__info span{
  font-weight: 400;
  font-size: 16px;
  color: #CCCCCC;
}
.right__info p{
  font-weight: 400;
  font-size: 16px;
  color: #1D252B;
  margin-top: 1rem;
  line-height: 2.2rem;
}

.right__info a{
  font-weight: 400;
  font-size: 16px;
  color: #1D252B;
}

.shop__text{
  margin-top: 3rem;
}

.shop__subscribe{
  display: flex;
  align-items: center;
  gap: 2rem;
  color: #181818;
  font-size: 14px;
}

.shop__text h3{
  margin:0.8rem 0rem;
}

@media only screen and (max-width: 1024px) {
  .overlay-search-mobil{
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .shop__info__item{
    padding-left: 0%;
  }
  .shop__info__modal{
    padding:2rem 0rem;
  }

  .modal-content{
    width: 95% !important;
  }
  
 
}