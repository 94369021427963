.shop__container {

    margin-top: 2rem !important;
    text-align: center;
    margin-bottom: 6rem !important;
}

.shop__container h3 {
    font-weight: 400;
    font-size: 22px;
    color: var(--color-black);
}

.shop__info {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 5rem 1fr;
    gap: 3rem;
    align-items: center;
}


.item {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    padding-top: 1rem;
}

.subscribe {
    display: flex;
    align-items: center;
    gap: 2rem;
    color: #181818;
    font-size: 14px;
}

.icons {
    margin-top: 2.4rem;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.icons a {
    font-size: 2.4rem;
    color: #B9B9B9;
}

.icons a:nth-child(1) {
    font-size: 2.7rem;
}

.lineBorder {
    width: 1.3px;
    height: 8rem;
    background: #181818;
    margin: 0px auto;
}

.item p {
    margin-bottom: 1.8rem;

}

.item p a {
    font-weight: 300;
    font-size: 14px;
    color: var(--color-black);
    line-height: 2rem;
    display: flex;
    align-items: center;
    gap: 0.7rem;
}

.item p a svg {
    color: var(--color-primary);
    font-size: 1.7rem;
}

.item2 {
    text-align: left;
}

.service {
    font-weight: 500;
    font-size: 18px;
    color: #D4252A;
    display: flex;
    align-items: center;
    gap: 2rem;
}

.item__right {
    text-align: left;
}

.item__right p {
    display: flex;
    gap: 1rem;
    align-items: center;
}

@media only screen and (max-width: 1024px) {
    .shop__container {
        display: none;
    }
}