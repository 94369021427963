.shopCard{
    padding:1rem;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
    transition: var(--transition);
}

.dealerCard{
    padding:0rem 1rem 1rem;
}

.shopCard  img{
    margin: 1rem  0rem;
    height: 8rem;
    object-fit: contain;
}

.dealerCard img{
    margin: 0rem  0rem;
}

.shop__card__link{
    display: flex;
    justify-content: center;
    position: relative;
    top:-2rem;
}

.shop__card__link img{
    width: 10.8rem;
    height: 10.8rem;
    border-radius: 100%;
}

.shopCard h3{
    font-weight: 400;
    font-size: 14px;
    line-height: 2rem;
    color: var(--color-black);
    text-align: center;
    min-height: 4rem;
}
/* .dealerCard h3{
    min-height: 5rem;
} */

.dealerCard__desc {
    font-weight: 500;
    font-size: 11px;
    color: #AFAFAF;
    text-align: center;
}

.shopCard__count {
    border-top: 1px solid #D0D0D0;
    padding: 1.4rem 0rem 0.5rem;
    text-align: center;
    margin-top:1rem;
}

.shopCard__count span{
    color: var(--color-primary);
    font-weight: 400;
    font-size: 14px;
   
}

.shopCard:hover{
    box-shadow: 3px 4px 9px rgba(36, 36, 36, 0.15);
}

@media only screen and (max-width:1024px) {
    .shop__card__link img{
        width: 8rem;
        height: 8rem;
    }
    .shopCard h3{
        font-size: 12px;
    }
  }