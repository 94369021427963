
.banner{
    width: 100%;
    display: flex;
}


.banner a{
    width: 100%;
    height: 100%;
    display: flex;
}

