.map_main {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0;
  margin: 0;
  /* height: 100vh; */
}
.goMapTop {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: flex-start;
}
.goMapTop__location {
  display: flex;
  gap: 0.8rem;
  margin-top: 1.5rem;
  font-weight: 500;
  font-size: 24px;
}
.goMapTop__location_parargraf {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: #181818;
}
.goMapTop__location_icon {
  color: #dbbf5c;
  font-size: 2rem;
}
.goMapTop__buttons {
  display: flex;
  /* margin: 1rem 0 3rem 25%; */
  margin: 2rem auto 3rem auto;
  width: fit-content;
  height: 3.8rem;
  padding: 0.3rem 0.3rem;
  border: 1px solid #bdbdbd;
  border-radius: 3px;
  text-align: center;
}
.goMapTop__buttons_btn {
  padding: 0.3rem 3rem;
  width: 12.6rem;
  height: 3rem;
  background-color: #fff;
  font-weight: 600;
  font-size: 1.6rem;
}
.goMapTop__buttons_btn:first-child:active {
  background-color: #bbbbbb;
}
.goMapTop__buttons button:hover {
  background-color: #e5e5e5;
}

.adress {
  display: flex;
  gap: 2.3rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 90rem;
  margin: 0 auto 0 auto;
}
.mercedes {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}
.adress__image {
  overflow: hidden;
  width: 29.5rem;
}
.adress__image img {
  background-position: center;
  height: 128px;
  
}

.adress__MercedesCenter {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  font-weight: 400;
  font-size: 1.2rem;
  /* border-right: 2px solid #bbbbbb; */
}
.direction {
  width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  height: 12.8rem;
}
.adress__MercedesCenter_info,
.direction__info {
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
}
.direction__info {
  font-size: 1.4rem;
  width: 24rem;
}
.adress__MercedesCenter_title,
.direction__title {
  font-weight: 500;
  font-size: 2rem;
  width: max-content;
}
.adress__MercedesCenter_info li:first-child {
  font-weight: 500;
  font-size: 1.4rem;
}
.adress__MercedesCenter_info li i {
  font-size: 1.4rem;
  margin-right: 1.7rem;
  color: #bbbbbb;
}
.direction__info li i {
  font-size: 1.4rem;
  margin-right: 1rem;
  color: #bbbbbb;
}
.direction__info li:nth-child(2) {
  display: flex;
  align-items: center;
}
.direction__info_trainText {
  margin-left: 1.6rem;
}
.direction__info_icon i {
  margin: 0 1rem;
  color: #D4252A !important;
}

.map {
  /* max-width: 144rem; */
  width: 100%;
  margin: 1rem auto;
  padding: 0 2rem;
}


@media (max-width: 356px) {
  .adress__image {
    height: auto;
    
  }
  .adress__image img {
    height: auto;
  }
  .mercedes {
    display: flex;
    flex-direction: column;
  }
  
  .adress__MercedesCenter {
    max-width: 356px;
    width: 100%;
  }
  .goMapTop__buttons {
    width: 25.6rem;
    padding: 0.3rem 0.2rem;
  }
  .goMapTop__buttons_btn {
    width: 8rem;
    padding: 0.3rem 0.3rem;
  }
  .adress__image {
    width: 25.5rem;
    object-fit: contain;
  }
  .adress__MercedesCenter_title,
  .direction__title {
    font-size: 1.6rem;
  }
}

