.container404 {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10.2rem;
    place-content: center;
    align-items: center;
    padding: 4rem 0rem;
}

.left {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
}

.left h1{
    font-weight: 800;
    font-size: 9.6rem;
    line-height: 3.3rem;
    letter-spacing: 0.0025em;
    color: var(--color-primary);
}

.left h3{
    font-weight: 600;
  font-size: 4.7rem;
  line-height: 4.7rem;
  text-align: center;
  color: var(--color-primary);

}


.left a {
    font-weight: 500;
    color: var(--color-black);
    display: flex;
    align-items: center;
    gap: 2.3rem;
}

@media only screen and (max-width: 1200px) {
    .container404 {
        column-gap: 5rem;
    }
    .left {
        gap: 2rem;
    }
    .left h1{
        font-size:7rem;
    }
    
    .left h3{
      font-size: 4rem;
    }
}

@media only screen and (max-width: 912px) {
    .container404 {
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap: 6rem;
        justify-content: center;
    }

    .right {
        order: -1;
        justify-self: center;
    }

    .left {
        justify-self: center;
    }


}