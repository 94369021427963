.open__form__modal {
    padding: 0px 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap:1rem;
}

.open__form__modal h3 {
    margin-top: 2rem;
    font-weight: 500;
    font-size: 18px;
    color: #181818;
    text-align: center;
    grid-column: 1 / -1;
}

.form__item {
    margin-top: 1.5rem;
}
.form__image{
    grid-column:  1 / -1;
}

.form__item input {
    width: 100%;
    height: 4.5rem;
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    padding: 10px;
    margin-top: 0.6rem;
}

.form__item label input {
    display: none;
}

.form__item .form__img {
    margin-top: 0.6rem;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-black);
    border-radius: 5px;
    cursor: pointer !important;
    color: #fff;
}

.succesImg {
    background: var(--color-primary)!important;
}

.form__item span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #8E8E8E;
}

.open__form__modal button {
    padding: 1rem 4rem;
    width: 100%;
    margin-top: 2rem;
    grid-column: 1 / -1;
}

.open__form__modal .form__item p {
    margin-top: 0.3rem;
    color: rgb(199, 51, 51);
}

.open__form__modal h4{
    grid-column: 1 / -1;
    font-size: 1.6rem;
}

.select__span{
    margin-bottom: 0.6rem;
    font-weight: 400;
    font-size: 12px;
    color: #8E8E8E;
    display: inline-block;
}


.image-preview {
    margin: 1rem 0;
    position: relative;
    height: 13rem;
    width: 15rem;
}

.preview-image {
    /*width: max-content;*/
    height: 11rem;
    object-fit: contain;
}
.remove-button {
    position: absolute;
    top: 0;
    right: -16px;
    border: none;
    background: transparent;
    border-radius: 50%;
    color: #8E8E8E;
    font-size: 17px;
    cursor: pointer;
}

.modalCart {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    height: 100%;
    text-align: center;
}

.modalCartFont h2 {
    font-weight: 600;
    font-size: 18px;
}

.modalCartFont h3 {
    font-weight: 400;
    font-size: 20px;
}

.modalCartFont p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .open__form__modal {
        padding: 0rem 0rem;
        grid-template-columns: 1fr;
    }
    .form__item {
        margin-top: 0.5rem;
    }

   
  }