.detail__container {
    margin-top: 4rem !important;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 3rem;
}

.detialText {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1.5rem;
    row-gap: 4rem;
}

.oem__date {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
}


.detialText h3 {
    margin-bottom: 0.8rem;
    font-weight: 400;
    font-size: 20px;
    color: var(--color-black);
}

.detialText strong {
    text-align: center;
}

.detail_flex {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    border-right: 1px solid #181818;
}

.detail_flex2 {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.tableDetail {
    width: 100%;
    border-collapse: collapse;
    margin-top: 4rem;
}

.tableDetail td,
.tableDetail th {
    text-align: center;
    color: var(--color-black);
}

.tableDetail td a {
    color: #737373;
}

.tableDetail thead {
    background: #F3F3F3;
    border-collapse: collapse;
}

.tableDetail thead th {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
}

.tableDetail td {
    font-weight: 400;
    font-size: 12px;
    padding: 5px 0px;
    color: #737373;
}

.buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 2.5rem;
}

.buttons a{
padding-bottom: 1rem !important;
}

.buttons :first-child{
    height: 3.8rem;
    display: flex;
    gap: 1.5rem;
    font-family: 'Roboto', sans-serif;
    border-radius: 3px !important;
    padding: 0;
    /* padding-bottom: 1rem; */
    font-weight: 400;

} 

.btn__common_call{
    height: 3.8rem;
    font-family: 'Roboto', sans-serif;
    width: 14.9rem;
}

 .btn_call{
    background-color: white;
    border: 1px solid #D8D8D8 !important;
    border-radius: 3px !important;
    color: #D4252A !important;
    
}

.buttons a {
    color: #545454;
}

.detailMap button {
    padding: 1rem 2rem !important;
    font-size: 14px;
}

.detailMap p a {
    display: flex;
    gap: 1.5rem;
    font-weight: 400;
    font-size: 12px;
    color: var(--color-black);
    line-height: 2px;

}

.detailMap p a svg {
    color: #BBBBBB;
    font-size: 1.6rem;
}

.showMap {
    font-size: 1.2rem;
    color: var(--color-primary);
}

.complaint {
    font-size: 1.2rem;
    color: #434242;
    display: flex;
    gap: 0.6rem;
}

.complaint svg {
    font-size: 1.8rem;
    color: #FE0000;
}

.discount {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    text-decoration: line-through;
    color: #FF0000;
}

.d_flex {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 3px;
}

.d_flex h3{
    gap: 1rem;
    display: flex;
    align-items: center;
}

.detial_p{
    line-height: 2.7rem;
}
.price {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #434242;
    display: none;
}

.d_flex .price {
    display: inline-block;
}

.seeMore {
    padding: 1rem;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 3rem;
}

.seeMore a {
    font-weight: 500;
    font-size: 13px;
    color: #181818;
}

.seeMore a:nth-last-child(1) {
    color: #4b4b4b;
}

.banerDetail {
    padding-top: 6rem;
}

.banerDetail img {
    height: 17.5rem;
}

.mobilTable {
    display: none;
}

.discountDemo{
    display: none;
}

.logoShop{
    margin-left: auto;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
    padding: 0.5rem 1.5rem; 
}

.logoShop img{
   width: 8rem;
   height: 3.7rem;
   object-fit: contain;

}

@media only screen and (max-width: 1280px) {
    .detail__container {
        margin-top: 3rem !important;
        grid-template-columns: 1fr 1fr;
    }

    .detialText {
        grid-template-columns: 1fr;
    }
}


@media only screen and (max-width: 1024px) {
    .tableDetail {
        display: none;
    }

    .price {
        display: inline-block;
    }
    .detialText .discount{
        display: block;
    }
    .d_flex .price {
        display: none;
    }
    .d_flex .discount{
        display: none;
    }

    .mobilTable {
        display: flex;
        margin-top: 5rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 2rem;
    }

    .detialText {
        gap: 1.5rem;
    }

    .mobilTable li a, .mobilTable li span {
        font-weight: 400;
        font-size: 12px;
        color: #181818;
    }

    .mobilTable ul li {
        list-style:disc;
        margin-bottom: 0.3rem;
    }
    
    .mobilTable h4{
        margin:0rem auto 1rem;
        font-size: 14px;
        text-align: center;
    }
}


@media only screen and (max-width: 767px) {
    .detail__container {
        margin-top: 1rem !important;
        grid-template-columns: 1fr;
        gap: 1rem;
    }
    .detailInfo {
        padding-top:0rem
    }
    
    .banerDetail img {
        height: 13.8rem;
    }
    .detialText h3{
        display: flex;
        justify-content: space-between;
        /* align-items: center; */
        gap:2rem;
    }
    .green_btn, .btn_call{
        width: 100%;
       }

       .detial_p{
        width: 30%;
        margin-left: auto;
        display: grid;
        place-content: flex-start flex-end;
    }
}

@media only screen and (max-width: 400px) {
   
    .detialText h3{ 
        justify-content: space-between;
        font-size: 1.8rem;
       
    }
    .logoShop{
        margin-right: auto;
        margin-left: 0;
    }
   .green_btn, .btn_call{
    width: 100%;
   }
   .detial_p{
    width: 30%;
    margin-left: auto;
    display: grid;
    place-content: flex-start flex-end;
}
   }
   
