nav {
    width: 100%;
}

.nav__top {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9.5rem;
    height: 3.5rem;
    background: var(--bg-gray2);
}

.nav__top a {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #737373;
}


/* nav__bottom */

.nav__bottom {
    display: flex;
    gap: 4rem;
    height: 6.9rem;
    align-items: center;
    justify-content: space-between;
}

.countUp {
    display: flex;
    align-items: center;
    gap: 5px;
    min-width: 15rem;
}

.countUp span {
    padding: 3px;
    letter-spacing: 1px;
    height: 27px;
    border-radius: 2px;
    color: #fff;
}

.flex {
    display: flex;
    align-items: center;
    gap: 1.8rem;
    height: 100%;

}

.hub {
    color: var(--color-black);
}

.formSearch {
    /* width: 42.2rem; */
    width: 45rem;
    height: 33px;
    border: 1px solid #C2C2C2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding-left: 2rem;
    background: #fff;
    /* z-index: 9999; */
    position: relative;
}

.nav__bottom form input {
    width: 90%;
    height: 100%;

}

.nav__bottom form button {
    width: 4rem;
    font-size: 1.5rem;
    background: transparent;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.langs {
    position: relative;
}

.langs a {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    padding-right: 1.5rem;
    border-right: 1px solid #B8BCB4;
    text-transform: uppercase;
}

.langs a i {
    padding-left: 0.5rem;
}

.lang {
    color: #949494;
}

.dropdown_lang {
    position: absolute;
    top: 30px;
    right: 15px;
    z-index: 999;
    padding: 6px;
    border-radius: 3px;
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.dropdown_lang a {
    padding: 6px;
    border: none;
    color: #949494;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.dropdown_lang a:hover {
    background: var(--color-primary);
    color: var(--color-white);
    border-radius: 2px;
    transition: var(--transition);
}


.openSearchMenu {
    position: absolute;
    top: 35px;
    right: 0px;
    width: 100%;
    background: var(--color-white);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 3px 0px;
    height: auto;
    overflow: auto;
    border-radius: 0px 0px 4px 4px;
    z-index: 99999999 !important;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding:1rem 2.5rem;

}

.openSearchMenu li a {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000;
}

.mobilFlex {
    display: none;
}

.mobilShow {
    display: none;
}

.mobilForm {
    display: none;
}

.visibleRegister {
    position: relative;
}

.visibleRegister .dropdown_lang {
    padding: 1rem 2rem 2rem;
}

.visibleRegister .registerLink {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 1rem 0rem; 
    width: 17rem;
    font-size: 15px;
}
.visibleRegister .registerLink a{
    width: 4rem;
    height: 4rem;
    background: #F2F2F2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.visibleRegister .dropdown_lang{
    top: 35px;
    right: -45px;
}

.btns__login{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.btns__login a{
    padding:1rem 2rem;
    color:#fff;
}

.btns__login a:hover{
    border-radius: 5px;
}

.sup{
    position: relative;
    top:-8px;
    left:-1px;
    color:var(--color-primary);
}

.searchContainer{
    margin-top:2rem;
    padding-top:1rem;
    border-top:1px solid var(--bg-gray2);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:2rem;
}

.boxSearch{
    text-align: center;
    padding: 0.5rem;
    position: relative;
}
.boxSearch img{
    height: 7rem;
    object-fit: contain;
}

.activeSearchForm{
    max-width: 75rem !important;
    width: 100% !important;
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
     z-index: 99999;
}
.mobilShowBanner{
    display: none;
}


.small_basket {
    position: absolute;
    background-color: #fff;
    padding: 3rem;
    border-radius: 5px;
    top: 6.6rem;
    right: 4rem;
    z-index: 99999;
    box-shadow: 1px 1px 10px #D0D0D0;
    display: flex;
    flex-direction: column;
    gap: 2.8rem;
}

.small_basket span {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.small_basket span h5{
    color: #000;
}
.product_img {
    width: 101px;
    height: 76px;
    border: 1px solid #D0D0D0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}
.product_info h3{
    margin-top: 2rem;
    font-size: 1.4rem;
    color: #000;
}
.product_info p {
    font-size: 1.2rem;
    margin-top: .5rem;
}
.product_img img {
    height: 59px;
    width: 88px;
}

.product_detail {
    display: flex;
    gap: 1.8rem;
}



@media only screen and (max-width: 1320px) {
    .nav__bottom form {
        width: 25rem;
    }

    .nav__bottom {
        gap: 1rem
    }
}

@media only screen and (max-width: 1024px) {
    .nav__top{
        display: none;
    }
    nav {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 9999999;
        background: var(--color-white);
        /* border-bottom: 1px solid #C2C2C2; */
    }
    .mobilShowBanner{
        display: flex;
    }
    .nav__bottom {
        display: grid !important;
        grid-template-columns: 25% 72%;
    }

    .mobilFlex {
        display: flex;
        padding-left: 5px;
    }

    .mobilHidden {
        display: none;
    }

    .mobilShow {
        display: flex;
        justify-content: flex-end;
        padding-right: 5px
    }

    .mobilShow button {
        background: transparent;
        padding-bottom: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .nav__bottom .desktopForm {
        display: none;
    }

    .logo {
        display: none;
    }

    .mobilLeft {
        padding: 4px;
        background: var(--color-primary);
        height: 100%;
        display: flex;
        align-items: center;
    }

    .countUp {
        min-width: max-content;
        justify-content: center;
        display: none;
    }

    .mobilForm {
        width: 94%;
        margin: 0px auto;
        /* height: 40px; */
        border: 1px solid #C2C2C2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        padding-left: 2rem;
        background: #fff;
        position: relative;
    }

    .mobilForm input {
        width: 90%;
        height: 100%;

    }

    .mobilForm button {
        width: 4rem;
        font-size: 1.5rem;
        background: transparent;
        height: 100%;
        cursor: pointer;
    }

    .mobilForm .openSearchMenu {
        top: 45px;
    }

    .searchContainer{
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
    .formSearch {
        width: 100%;
        padding-left: 1rem;
    }

    .activeSearchForm{
        max-width:59rem !important;
    }
    .nav__bottom form {
        width: 100%;
    }
 
}


@media only screen and (max-width: 600px) {
    .activeSearchForm{
        width: 95% !important;
    }
    .searchContainer{
        grid-template-columns: repeat(2, 1fr);
    }
    .nav__top {
        justify-content: space-around;
        gap: 1rem;
    }

    .nav__top a svg {
        width: 2rem;
        height: 1.3rem;
    }

    .countUp span {
        padding: 2px;
    }
    .openSearchMenu {
        padding:1rem 1.5rem;
    
    }
}