.order-list-tabs{
    display: flex;
    gap:2rem;
    align-items: center;
    margin-bottom: 4rem;
}


.order-list-item{
    display: grid;
    grid-template-columns:1fr 9fr;
    place-items: top;
    gap:2rem;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding:1.9rem 5rem 1.9rem 0.5rem; 
    position: relative;
    margin-bottom: 2rem;
}

.order-list-item-img{
    display: flex;
    flex-direction: column;
    gap:1rem;
    align-items: center;
}

.order-list-item-img img{
    width: 59px;
    height: 59px;
    border: 1px solid #D0D0D0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-list-item .downIcon{
    background: transparent;
    cursor: pointer;
    font-size: 2rem;
    position: absolute;
    right: 2rem;
    top:5rem;
    color: #858585;
    
}

.order-item-info{
    font-weight: 400;
    font-size: 10px;
    color: var(--color-black);
}

.order-list-item-info{
    width: 100%;
    height: 100%;
}

.order-list-item-info table{
    width: 100%;
}

.order-list-item-info table thead th{
    font-weight: 400;
    font-size: 12px;
    color: #7D7D7D;
    text-align: left;
}

.order-list-item-info table tbody td{
    font-weight: 500;
    font-size: 12px;
    color: var(--color-black);
    text-align: left;
}


.steps{
    width: 100%;
    padding:4rem 0rem;
    display: flex;
    justify-content: space-evenly;
    display: none;
}
.activeSteps{
    display: flex;
}
.step-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.step {
  border: none;
  background-color: #D8D8D8;
  color: #C0C0C0;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  margin-bottom: 1.3rem;
}

.step.activeStep {
  background-color: var(--color-primary);
  color: #fff;
}

.step-item p{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #181818;
    margin-bottom: 0.9rem;
}
.step-item span{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #858585;
}

.step-line{
    position: absolute;
    top: 2rem;
    left: 6rem;
    width: 11.5rem;
    height: 2px;
    background: #D0D0D0;
}

.step-item:nth-last-child(1) .step-line{
    display: none;
}

.info-mobil{
    display: none;
}

.success{
    color: var(--color-primary);
}

.danger {
    color: #FB3939;
}

.return {
    color: #FFB930;
}

@media only screen and (max-width: 820px) {
    .order-list-item-info table{
        display: none;
    }
    .info-mobil{
        display: grid;
        gap: 1rem;
    }
    .steps{
        justify-content: space-between;
        gap:1rem;
        flex-wrap: wrap;
    }
   
}


@media only screen and (max-width:600px){
    .step-line{
        display: none;
    }
    .step-item p {
        font-size: 13px;
    }
    .order-list-tabs {
        flex-wrap: wrap;
        gap: 1rem;
    }
    .order-list-tabs button{
            width: 100%;
    }
}