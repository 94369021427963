.video__container{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(28rem , 1fr));
    gap:2rem;
}

.video__container article{
    height: 22rem;
    position: relative;
     border-radius: 3px;
}

.video__container article iframe{
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  .modal.is-open {
    opacity: 1;
    visibility: visible;
  }
  
  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content2 {
    width: 70%;
    height: auto;
    position: relative;
  }
  
  .modal-close-video {
    position: absolute;
    top: -10px;
    right: -20px;
    border: none;
    background-color: transparent;
    cursor: pointer !important;
    color:#fff;
    font-size: 2rem;
    font-weight: 600;
  }
  

  .modal-content2 iframe{
    width: 100% !important;
    height: 56rem !important;
    border-radius: 3px;
  }


.video-overlay{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
     border-radius: 3px;
}

.video-overlay h4{
  font-weight: 400;
  font-size: 3.4rem;
  padding:0% 10%;
  color:#fff;
  line-height: 32px;
}

.video-overlay span{
    position: absolute;
    left: 1.5rem;
    bottom: 1.5rem;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.video-overlay span svg{
  background: transparent;
}

.video-title{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top:6rem;
    margin-bottom: 2rem;
}

@media only screen and (max-width: 1024px) {
  .video-title{
    margin-top:3rem;
}
.video__container{
  grid-template-columns: repeat(auto-fill , minmax(22rem , 1fr));
}
.video__container article{
  height: 19rem;
}
.video-overlay h4{
  font-size: 3rem;
  line-height: 26px;
}
.modal-content2 iframe {
  height: 39rem !important;
}
.modal-content2 {
  width: 80%;
}

}


@media only screen and (max-width: 600px) {
  .modal-content2 iframe {
    height: 29rem !important;
}
.modal-content2 {
  width: 94%;
}
.modal-close-video{
  display: none;
}
}