.head {
  color: #181818;
  font-weight: 500;
  font-size: 24px;
  margin: 4rem 0 3rem;
  text-align: center;
  line-height: 20px;
}
.headcolor {
  color: #3d3d3d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  line-height: 20px;
}

.greenhead {
  font-size: 32px;
  font-weight: 500;
  color: #D4252A;
  line-height: 40px;
}

.often {
  font-size: 20px;
  font-weight: 500;
  color: #c7c7c7;
  margin-bottom: 2rem;
}

:is(.contents, .right__part) p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 2rem;
  color: #181818;
  letter-spacing: 0.3px;
  line-height: 24px;
}

.right__part h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 1.3rem;
}

.comman_wrapper {
  display: grid;
  grid-template-columns: 24.8rem 4fr;
  column-gap: 3rem;
}

.comman_wrapper2 {
  display: grid;
  grid-template-columns: 4fr 2fr;
  column-gap: 3rem;
}

.left {
  max-height: 65rem;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  border: 1px solid rgba(241, 241, 241, 1);
  border-radius: 10px;
  padding: 2rem;
  background-color: #fcfcfc;
  gap: 3rem;
}

.term_list{
  padding: 0rem 2rem;

}
.term_list li{
  list-style-type: disc;
  padding:  1rem;
  font-size: 14px;
  font-weight: 300;
  color: #181818;
  letter-spacing: 0.3px;
  line-height: 24px;

}

.left p {
  font-size: 12px;
}
.left div:nth-of-type(1) {
  padding-bottom: 2rem;
  border-bottom: 2px solid #f1f1f1;
}

.part1 {
  display: grid;
  gap: 15px;
}

.part1 :nth-child(1) {
  text-align: center;
}

.contact_form {
  display: grid;
  place-items: center center;
  width: 40%;
  margin: auto;
  gap: 2rem;
  margin-top: 4rem;
}

.form_item {
  width: 100%;
}

.form_item input {
  width: 100%;
  height: 52px;
  background: #fcfcfc;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding-left: 1.5rem;
  border-left: 3px solid #D4252A;
}

.form_item input ::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #cccccc;
}

.form_item textarea {
  width: 100%;
  background: #fcfcfc;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 1.5rem 0 0 1.5rem;
  border-left: 3px solid #D4252A;
}

.form_item textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #cccccc;
}

.send_btn {
  margin-left: auto;
  width: 141px;
  height: 45px;
  margin-top: 2rem;
}

.lnone {
  position: absolute;
  display: none;
}

.about__list{
  padding-left: 2rem;
}

.about__list li{
  font-size: 14px;
  font-weight: 300;
  margin: 1.5rem;
  color: #181818;
  letter-spacing: 0.3px;
  line-height: 24px;
  list-style-type: disc;
}

@media only screen and (max-width: 992px) {
  .comman_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
  .comman_wrapper2 {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }

  .comman_wrapper2 :last-child {
    order: -1;
  }

  .left {
    display: flex;
    flex-direction: row;
  }

  .left div:nth-of-type(1) {
    padding-right: 2rem;
    border-right: 2px solid #f1f1f1;
    border-bottom: none;
  }

  .contact_form {
    display: grid;
    place-items: center center;
    width: 50%;
    margin: auto;
    gap: 2rem;
  }

  .right__part h3 {
    font-size: 15px;
    font-weight: 400;
    order: -1;
  }
  .right__part p {
    font-size: 13px;
    font-weight: 300;
  }
  .dnone {
    display: none !important;
  }

  .lnone {
    position: absolute;
    display: block;
  }
}

@media only screen and (max-width: 700px) {
  .head {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }

  .greenhead {
    font-size: 26px;
    font-weight: 500;
    line-height: 40px;
  }
  .contents p {
    font-size: 14px;
    font-weight: 300;
  }
  .right__part h3 {
    font-size: 15px;
    font-weight: 400;
    order: -1;
  }
  .right__part p {
    font-size: 13px;
    font-weight: 300;
  }

  .left {
    display: flex;
    flex-direction: row;
    gap: 0;
    align-items: stretch;
  }
  .left h3 {
    font-size: 14px;
    font-weight: 500;
  }

  .left div:nth-of-type(1) {
    padding-right: 1.5rem;
    border-right: 2px solid #f1f1f1;
    border-bottom: none;
    padding-bottom: 0;
  }

  .left div:nth-of-type(2) {
    padding-left: 1.5rem;
  }

  .part1 {
    display: grid;
    gap: 13px;
  }

  .comman_wrapper2 {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 3rem;
  }

  .comman_wrapper2 :last-child {
    order: -1;
  }

  .contact_form {
    display: grid;
    place-items: center center;
    width: 60%;
    margin: auto;
    gap: 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .head {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }

  .greenhead {
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
  }

  .contact_form {
    display: grid;
    place-items: center center;
    width: 95%;
    margin: auto;
    gap: 2rem;
  }

  .send_btn {
    width: 100%;
  }

  .comman_wrapper2 :nth-of-type(2) {
    order: -1;
    text-align: center;
  }
}

@media only screen and (max-width: 361px) {
  .head {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
  }

  .contents p {
    font-size: 13px;
    font-weight: 300;
  }

  .greenhead {
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
  }
  .left {
    display: flex;
    flex-direction: column;
  }

  .left div:nth-of-type(1) {
    padding-bottom: 1rem;
    border-bottom: 2px solid #f1f1f1;
    border-right: none;
  }
}
