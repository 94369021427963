/* slider */
.slider__title {
  display: flex;
  padding: 2.2rem 0rem 1rem;
  justify-content: space-between;
  align-items: center;
}

.slider__title h4 {
  color: var(--color-black);
  font-size: 1.6rem;
}

.slider__title a {
  font-weight: 500;
  font-size: 14px;
  color: #AEAEAE;
  margin-right: 1rem;
}

.slick-track {
  padding: 5px 0px;
}

.slick-slider .slick-prev {
  display: none !important;
}

.slick-slider .slick-next {
  display: none !important;
}

.slick-slider .slider__card {
  width: 20rem;
  border: none !important
}

/* detail slider */
.detailSlider {
  width: 32rem;
}

.detailSlider .smallImages {
  display: flex !important;
}

.detailSlider .smallImages .slick-slide {
  width: 80px !important;
}

.detailSlider .smallImages .slick-current img {
  border: 1px solid #AEAEAE !important;
}

.detailSlider .slick-list {
  width: 100%;
}

.oneImg {
  border-radius: 3px;
  border: 1px solid #D0D0D0;
  width: 30rem;
  height: 24rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.oneImg img {
  height: 206px;
  object-fit: contain;
}

.smallImages {
  width: 100% !important;
  display: flex !important;
  gap: 1rem !important;
}

.smallImg img {
  width: 74px !important;
  height: 54px !important;
  cursor: pointer;
  border-radius: 1px;
  border: 1px solid #D0D0D0;
}

.slick-slide article {
  height: auto;
  background: #fff !important;
}

.avatar-skeleton {
  width: 100%;
  height: 100%;
  line-height: 1;
}

.sklet__slider {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  gap: 2rem;
}


@media only screen and (max-width: 600px) {

  .slick-slider .slider__card {
    width: 18rem;
  }

  .sklet__slider {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    gap: 1rem;
  }
  
}

@media only screen and (max-width: 400px) {
  .slick-slider .slider__card {
    width: 17rem;
  }
}

@media only screen and (max-width: 355px) {
  .slick-slider .slider__card {
    width: 15rem;
  }
}