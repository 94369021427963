.stickyFilter{
    width: 100%;
    position: fixed;
    top:0px;
    z-index: 999;
    left: 0px;
    background: #515151;
    padding:1rem 0rem;
    transition:var(--transition)
}

.absoluteFilter{
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.filter__container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:2.7rem;
}

.homePosition{
    position: absolute!important;
}


.filter__container form{
    display: flex;
    gap:1rem;
    z-index: 9999;
    align-items: center;
}

.filter__container form input{
    border-radius: 2px;
    background: transparent;
}

.filter__container .selectItem{
    width: 20.5rem;
    font-size: 1.6rem;
}
.filter__container .selectItem::placeholder{
    color: red !important;
}
.oemcode::placeholder{
    color: #ADADAD;
}

.btn__filter__search{
    width: 3.8rem;
    height: 3.8rem;
    background: var(--color-primary);
    border-radius: 2px;
    color:var(--color-white);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}




@media only screen and (max-width: 1024px) {
    .filter__container {
        display: none;
    }
    .filterFixed{
        display: none;
    }
}