.menu__container {
    width: 100%;
    background: var(--bg-gray);
    height: 4.5rem;
}

.menu {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu li a {
    font-weight: 500;
    font-size: 15px;
    color: var(--color-white);
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 1.6rem;
}

.menu li a img {
    width: 1.8rem;
}

#closeMenu {
    display: none;
}



@media only screen and (max-width: 1024px) {
    .menu__container {
        height: 0px;
    }
    #closeMenu {
        top: 1.5rem;
        position: absolute;
        right: 2rem;
        font-size: 2rem;
        background: transparent;
        cursor: pointer;
        display: block;
    }
    
    .menu {
        flex-direction: column;
        max-width: 450px;
        width: 100%;
        left: 0px;
        top: 0px;
        position: fixed;
        z-index: 999999999;
        height: 100vh;
        background: #fff;
        box-shadow: 0px 3px 5px 0px #0000000d;
        padding: 5rem 0rem 2rem 3rem;
        transform: translateX(-100%);
        transition: 0.3s ease-in;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 3.5rem;
        overflow: auto;
    }

    .menu li {
        width: 80%;
    }

    .menu li a {
        font-weight: 500;
        font-size: 14px;
        color: #1D252B;
        gap: 1.6rem;
    }

    .mobil_flex a {
        display: flex;
        justify-content: space-between;
    }

    .mobil_flex span {
        width: 49px;
        height: 29px;
        background: #EFEFEF;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .menu li p {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        font-weight: 500;
        font-size: 14px;
        color: #1D252B;
        gap: 1.6rem;
    }

    /* Active Class */
    .nav__active {
        transform: translateX(0%);
    }

}