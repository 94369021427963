.service__card{
    /* width: 20rem; */
    position: relative;
}

.service__card .service__card__img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service__card .service__card__img img{
    border-radius: 5px 5px 0px 0px;
    object-fit: cover;
    object-position: center;
    height: 20rem;
}

.service__card__body{
    padding:1rem;
    background: #F3F3F3;
    border: 1px solid #D0D0D0;
}

.service__card__body p{
    color: #858585;
    margin-top: 1.3rem;
    display: flex;
    align-items: center;
    gap:1rem;
}
.service__card__body p a{
    color: #858585;
    display: flex;
    align-items: center;
    gap:1rem;
}

.service__card__body p svg{
    color:var(--color-primary);
    font-size: 1.8rem;
    width: 2rem;
}

.service__card__btn{
    position: relative;
    bottom: -5px;
    border-radius: 0px 0px 15px 15px;
}


@media only screen and (max-width: 600px) {
   
.service__card__body{
    border: none;
}

.service__card__btn{
     bottom: 0px; 
    border-radius: 0px 0px 5px 5px;
    font-size: 1.4rem !important;
    padding: 1rem 1.5rem !important;
}
.service__card__body p svg{
    font-size: 1.5rem;
    width: 1.5rem;
}


}

