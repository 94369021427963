.mobilNav{
    width: 100%;
    background: #F6F6F6;
    position: fixed;
    bottom: 0px;
    display: none;
}
.mobilNav ul{
    height: 7.4rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
}

.mobilNav ul a{
    display: flex;
    flex-direction: column;
    gap:1rem;
    align-items: center;
    color: #CCCCCC;

}

.ads button{
    width: 4.1rem;
    height: 4.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: var(--color-primary);
    font-size: 3rem;
    color:var(--color-white)
}

.activeNav{
    color: var(--color-primary) !important; 
}
.activeNav span{
    color: var( --bg-gray) !important;
}

@media only screen and (max-width: 1024px) {
    .mobilNav{
        display: block;
    }
    .ads{
        position: relative;
        top:-2rem;
        color: var(--color-primary) !important;
    }
    .mobilNav ul a{
        font-size: 1.1rem;
}
}

@media only screen and (max-width: 600px) {
    .mobilNav ul a{
        font-size: 0.8rem;
}
       .mobilNav ul a svg{
        height: 2.2rem;
}
}