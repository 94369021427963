footer{
    margin-top: 10rem; 
    background: #F1F1F1;
}

.foot{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:2rem;
    padding:3.2rem 0rem;
}

.socialIcons{
    display: flex;
    flex-direction: column;
    gap:1.5rem;
}

.socialIcons p a{
    font-weight: 400;
    font-size: 16px;
    color: #3D3D3D;
}

.footIcon{
    display: flex;
    align-items: center;
    gap:1.8rem;
}
.footIcon p{
    font-weight: 400;
    font-size: 16px;
    color: #3D3D3D;
}

.footIcon ul{
    display: flex;
    gap:1.5rem;
    align-items: center;
}

.footIcon ul a{
    color: #3D3D3D;
    font-size: 2.1rem;
}
.footIcon ul a:nth-last-child(1){
    font-size: 1.9rem;
}
.footIcon ul a:nth-last-child(2){
    font-size: 2.2rem;
}
.copyright{
    background-color: var(--color-white);
}

.copyright__container{
    height: 5.4rem;
    display: flex;
    justify-content: space-between;
    gap:2rem;
    align-items: center;
}
.copyright__container ul{
    display: flex;
    gap:4.6rem;
    align-items: center;
}

.copyright__container ul li a{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #989898;
}
.copyright__container p{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #989898;
}

.mobil__foot__icon{
    display: none;
}

@media only screen and (max-width: 1024px) {
  
    .copyright{
        background: #ECECEC;
    }
    .copyright__container{
        height: auto;
        padding: 1.5rem 0rem ;
        flex-direction: column-reverse;
        justify-content: center;
        gap:1rem;
    }
    .copyright__container ul li a{
        color:#434242;
    }
}


@media only screen and (max-width: 600px) {
    footer{
        margin-top: 5rem; 
    }
   
    .copyright__container ul {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
        column-gap: 2rem;
    }
    .mobil__foot__icon{
        display: block;
    }
    .socialIcons .footIcon{
        display: none;
    }
    .socialIcons p a{
        font-size: 13px;
    }
    .socialIcons p a span{
        display: block;
        padding-bottom: 0.6rem;
    }
    .mobil__foot__icon p{
        padding-top: 1.5rem;
        padding-bottom: 0.8rem;
    }
    .copyright__container p{
        font-size: 13px;
    margin-top: 1rem;
    }
}