.brand{
    padding:6rem 0rem 2.5rem;
    display: grid;
    grid-template-columns: 20rem 4fr ;
    gap:4rem;
}

.brand h3{
    grid-column: 1 / -1;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.brand__container{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(14rem , 1fr));
    gap: 2.6rem;
}


.allBrand{
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(14.6rem , 1fr));
    padding:2rem;
    background: #F1F1F1;
    border-radius:5px;
}
.allBrand a{
    color: var(--color-black);
    font-size: 1.3rem;
}

@media only screen and (max-width: 820px) {
    .brand{
        grid-template-columns: 1fr ;
        gap:2rem;
        padding:2.5rem 0rem ;
    }
    .brand__container{
        grid-template-columns: repeat(auto-fill , minmax(11.6rem , 1fr));
        gap: 1.6rem;
    }
   
  }
