.wishlist__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wishlist__title h3{
    font-weight: 400;
    font-size: 18px;
    color: var(--color-black);
}

.wishlist__title p{
    font-weight: 400;
    font-size: 14px;
    color: #A0A0A0;
}

.wishlist__title p strong{
    font-weight: 600;
    font-size: 16px;
    color: var(--color-black);
}

.wishlist__cards{
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fill , minmax(17rem , 1fr));
    gap:2rem;
}

@media only screen and (max-width: 820px) {
    .wishlist__cards{
        grid-template-columns: repeat(auto-fit , minmax(15rem , 1fr));
    }
    .wishlist__title h3{
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .wishlist__cards{
        gap:1rem;
    }
}