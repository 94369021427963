.card{
    position: relative;
    border: 1px solid #D0D0D0;
    border-radius: 5px;
}

.card .card__image{
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 0px 0px;
}

.card .card__image img{
    border-radius: 5px 5px 0px 0px;
    height: 12.7rem;
    object-position: center;
}

.card__body{
    border-radius:0px 0px 5px 5px ;
    padding:1.3rem 1rem 1.3rem;
    background-color: var(--color-white);
}

.d_flex{
    display: flex;
    gap:1rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;
    flex-wrap: wrap;
    position: relative;
}

.card span{
    position: absolute;
    top:1.4rem;
    right:1.3rem;
    cursor: pointer;
}

.card:hover{
    transition: var(--transition);
    background: #FFFFFF;
    box-shadow: 3px 4px 9px rgba(36, 36, 36, 0.15);
    border: 1px solid transparent;
}

.discount{
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-decoration: line-through;
    color: #FF0000;
}

.d_flex p{
    display: flex;
    gap:0.5rem;
    align-items: center;
}
/* .card__text{
    height: 3rem;
    overflow: hidden;
} */

.card__bottom{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    
}
.card__bottom p{
    color: #979797;
    font-size: 1.2rem;
}
@media only screen and (max-width: 600px) {
    .d_flex strong{
        font-size: 1.4rem;
    }
    .card__text{
        font-size: 1.1rem;
    }
}