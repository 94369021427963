.image {
    width: 69px;
    height: 69px;
    border: 1px solid #D0D0D0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cartItem img {
    display: inline-block;
    object-fit: cover;
}

.cartItem {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    place-items: center;
    gap: 2rem;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding: 2.5rem 1rem;
    padding-left: 5.5rem;
    position: relative;
}

.item__2 {
    grid-column: 2 / 4;
}

.item__3 {
    grid-column: 4 / 6;
}

.btns {
    display: flex;
}

.btns button {
    font-size: 1.8rem;
    padding: 5px 10px;
}

.btns button:nth-last-child(2) {
    background-color: var(--color-primary);
    color: var(--color-white)
}

.btns button:nth-child(1) {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    cursor: pointer;
}

.btns button:nth-last-child(1) {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    cursor: pointer;
}

.promocod {
    display: flex;
    position: relative;
}

.promocod span {
    position: absolute;
    top: -25px;
    font-weight: 500;
    font-size: 12px;
    color: #858585;
}

.promocod input {
    width: 137px;
    height: 41px;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    padding-left: 12px;

}

.remove {
    background: transparent;
    font-weight: 600;
    font-size: 1.5rem;
    cursor: pointer;
    position: relative;
    right: 1rem;
}

.check {
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 1rem;
}


.mobilCartItem{
    display: none;
}

.carLists{
    max-width: 160px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
}

@media only screen and (max-width: 768px) {
    .cartItem{
        display: none;
    }
    .mobilCartItem {
        display: grid;
        grid-template-columns: 78% 15%;
        gap: 3%;
        border: 1px solid #D1D1D1;
        border-radius: 5px;
        padding: 2.5rem 1rem;
        padding-left: 3.5rem;
        position: relative;
        margin-bottom: 0.5rem;
    }

    .mobilCartLeft {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        align-items: center;
    }

    .mobilCartName {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .mobilCartRight {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        justify-content: center;
        gap: 3rem;
    }

    .mobilCheck {
        top: 2.5rem;
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        left: 1rem;
    }

    .btns button {
        font-size: 1.5rem;
        padding: 5px 8px;
    }

    .mobilRemove {
        background: transparent;
        font-weight: 600;
    }

    .mobilPromocod {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;
    }

    .mobilPromocod span {
        font-weight: 500;
        font-size: 10px;
        color: #858585;
    }

    .mobilPromocod input {
        background: #F5F5F5;
        border-radius: 5px;
        width: 12.4rem;
        padding: 1rem;
    }

    .mobilCartRight h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #181818;
        min-width: 7rem;
    }
}

@media only screen and (max-width: 600px) {
    .mobilCartLeft {
        flex-direction: column;
        align-items: flex-start;
    }
}