.register__container {
    padding: 4rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
}

.register__container form {
    width: 38rem;
}

.register__container h3 {
    text-align: center;
    margin-bottom: 3rem;
}

.register__container form span {
    font-weight: 300;
    font-size: 14px;
    color: #8E8E8E;
}

.register__container form button {
    margin:0px auto;
}

.register__container a{
    font-weight: 400;
    font-size: 14px;
    color: #181818;
    border-bottom: 1px solid #181818;
}

.modalCart {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    height: 100%;
    text-align: center;
}

.modalCartFont h2 {
    font-weight: 600;
    font-size: 18px;
}

.modalCartFont h3 {
    font-weight: 400;
    font-size: 20px;
}

.modalCartFont p {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}

.eyeIcon{
    position: absolute;
    color: #8E8E8E;
    cursor: pointer;
    top:50%;
    right: 1.5rem;      
}


@media only screen and (max-width: 600px) {
    .register__container form {
        width: 100%;
    }
}