.privateInfo__container{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap:7.5rem;
}
.privateInfo__container form h3{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;
    font-weight: 500;
    font-size: 18px;
    color: #181818;
    margin-bottom: 2rem;
}

.privateInfo__container form h3 svg{
    color: #EBEBEB;
    font-size: 3.5rem;
}
.privateForm {
    margin-bottom: 2rem;
    position: relative;
}
.privateFormSelect {
    margin-bottom: 2rem;
}
.privateForm input{
    width: 100%;
    height: 50px;
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    padding:10px;
    margin-top: 0.6rem;
 }

 .privateInfo__container form span{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color:#8E8E8E;
}
.privateFormSelect .css-13cymwt-control{
    min-height: 5rem;
}
.privateFormSelect .css-t3ipsp-control{
    min-height: 5rem;
}
.privateInfo__container form  button{
    margin-left: auto;
}

.privateFormSelect .selectItems{
    margin-top: 0.6rem;
}

@media only screen and (max-width: 820px) {
    .privateInfo__container{
        grid-template-columns: 1fr;
        gap:0rem;
    }
    .privateInfo__container form h3 {
       flex-direction: row;
       margin-bottom: 1rem;
       font-size: 1.4rem;
    }
    .privateInfo__container form h3 svg {
        font-size: 2rem;
    }
}
